<template>
  <div>
    Select Direction
    <v-radio-group v-model="containerDirection">
      <v-radio label="Horizontal" value="horizontal" />
      <v-radio label="Vertical" value="vertical" />
    </v-radio-group>
    Select Alignment Type
    <v-radio-group v-model="containerAlignmentType">
      <v-radio label="Simple" value="simple" />
      <v-radio label="Accordian" value="accordian" />
      <v-radio label="Card" value="card" />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  computed: {
    containerDirection: {
      get() {
        return this.$store.state.ReportBuilder.containerDirection
      },
      set(value) {
        this.$store.commit(
          'ReportBuilder/setIsAllElemsAndCurrentLayoutSaved',
          false
        )
        this.$store.commit('ReportBuilder/updateContainerDirection', value)
      }
    },
    containerAlignmentType: {
      get() {
        return this.$store.state.ReportBuilder.containerAlignmentType
      },
      set(value) {
        this.$store.commit(
          'ReportBuilder/setIsAllElemsAndCurrentLayoutSaved',
          false
        )
        this.$store.commit('ReportBuilder/updateContainerAlignmentType', value)
      }
    }
  }
}
</script>

<style scoped>
</style>